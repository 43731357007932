






















































































































































import Vue from 'vue'
import api, { ProductsApi } from '@/api'

import { mapGetters } from 'vuex'
import { hasHistory, selectWith } from 'vuelpers'
import { Enquiry, Id, Product, TimkenAlias, User, VFormRef } from '@/types'
import { getValidators } from '@/mixins/FormMixin'
import { Accessors } from 'vue/types/options'

import SupplierCardMini from '@/components/SupplierCardMini.vue'
import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'
import ProductPreviewCard from '@/components/ProductPreviewCard.vue'

const newEnquiry = (v?: Enquiry): Partial<Enquiry> => ({
	vContactName: v?.vContactName ?? '',
	vContactEmail: v?.vContactEmail ?? '',
	vContactPhone: v?.vContactPhone ?? '',
	vContactMessage: v?.vContactMessage ?? '',
	bIsPriceQuote: v?.bIsPriceQuote ?? false,
	dRequestedQuantity: v?.dRequestedQuantity ?? 1,
})

export default Vue.extend({
	name: 'Enquiries',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Enquiries | ${APP_META.title}`,
		}
	},
	components: { SupplierCardMini, ProductPreviewCard },
	data: () => ({
		isBot: '',
		isLoading: false,
		isFormValid: false,
		enquiry: newEnquiry(),
		rules: getValidators('required', 'email', 'min'),

		product: null as Product | null,
		timkenAlias: null as Required<TimkenAlias> | null,
	}),
	created() {
		if (this.iProductId) this.onFetchProductById()
		else if (this.iTimkenAliasId) this.onFetchTimkenAlias()

		if (this.$currentUser) {
			this.enquiry = {
				...this.enquiry,
				vContactName: this.$helper.fullName(this.$currentUser),
				vContactEmail: this.$currentUser.email,
				vContactPhone: this.$currentUser.vMobileNumber,
			}
		}
	},
	computed: {
		...({
			...mapGetters('auth', ['$currentUser']),
		} as Accessors<{
			$currentUser: User
		}>),
		iProductId(): Id {
			return this.$route.query.iProductId as Id
		},
		iTimkenAliasId(): Id {
			return this.$route.query.iTimkenAliasId as Id
		},
	},
	methods: {
		async onFetchProductById() {
			const [err, res] = await ProductsApi.show(this.iProductId, {
				with: selectWith<Product>([
					'iProductId',
					'vDescription',
					'vDescription2',
					'vImage',
					'iQuickFind',
					'vBrand',
					{
						timkenAliases: [],
					},
				]),
			})
			if (!err) this.product = res.product
		},
		onFetchTimkenAlias() {
			api.get(`/timken-alias/${this.iTimkenAliasId}`, {}).then(
				([error, res]) => {
					if (error) {
						this.$helper.toast({
							error,
							errorMessage: 'Failed to get Timken Alias',
						})
						// hasHistory()
						// 	? this.$router.back()
						// 	: this.$router.push('/suppliers')
						// return
					}
					this.timkenAlias = res.timkenAlias
					this.product = res.timkenAlias?.product
					if (
						this.timkenAlias &&
						this.timkenAlias?.idProduct &&
						this.timkenAlias.idProduct > 0 &&
						!this.timkenAlias.product
					) {
						this.$helper.toast({
							error: true,
							errorMessage: `Timken Alias has \`idProduct\`=${this.timkenAlias.idProduct} but no product found in \`products\` table with this \`idProduct\`.`,
						})
						hasHistory() ? this.$router.back() : this.$router.push('/')
						return
					}
					this.enquiry.idTimkenAlias =
						this.timkenAlias?.iTimkenAliasId || this.iTimkenAliasId
				}
			)
		},
		onSubmit(): any {
			const formRef = this.$refs.formRef as VFormRef
			if (!formRef.validate()) return

			if (this.isBot) {
				formRef.reset()
				return this.$toast.error(
					"Great! You have proven to be a bot! I've destroyed the form the page, and acted like you successfully sent the form."
				)
			}

			this.isLoading = true
			api.post('/send-enquiry', this.enquiry).then(([err]) => {
				this.isLoading = false
				if (err) return this.$toast.error(err.message)

				this.$toast.success('Enquiry submitted successfully')
				this.onBack()
			})
		},
		onBack() {
			hasHistory() ? this.$router.back() : this.$router.push('/')
		},
	},
})
