




























































import { Supplier } from '@/types'
import Vue from 'vue'
export default Vue.extend({
	name: 'ComponentName',
	props: {
		hideCount: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		itemsCount: {
			default: 0,
			type: Number as Vue.PropType<number>,
		},
		isLoaded: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		supplier: {
			required: true,
			type: Object as Vue.PropType<Supplier>,
		},
	},
})
